import { createApp } from 'vue';
import App from './App.vue';
import setup from './utils/setup';

const app = createApp(App);
setup(app);

app.mount('#app');

export default app;
