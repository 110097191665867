import router from '@/utils/router';
import {
	callApiAuth,
	GeneralConstants,
	callApiAuthGo,
} from '@/utils/jleaf-api-connect';
import { defineStore } from 'pinia';

export const useAuthStore = defineStore({
	id: 'auth.store',
	state: () => ({
		username: '',
		token: '',
		password: '',
		submitted: false,
		disabled: false,
	}),
	actions: {
		handleSubmit(input) {
			this.submitted = true;

			if (!this.username || !this.password) {
				return;
			}
			this.onLogin(input);
		},
		async onLogin(dataFromVue) {
			this.disabled = true;
			const result = await callApiAuth({
				username: this.username,
				password: this.password,
			});
			if (result.isOk) {
				this.token = localStorage.jwtTask;
				this.disabled = false;
				this.submitted = false;
				localStorage.setItem('userData', JSON.stringify(result.body));
				router.push({ name: process.env.VUE_APP_HOME_BASE_ROUTER });
			} else {
				this.disabled = false;
				dataFromVue.$refs.toast.showToast({
					severity: 'error',
					summary: 'label.error',
					detail: result.errorMessage,
					args: result.errorArgs,
				});
			}
		},
		onLogout() {
			this.submitted = false;
			this.token = '';
			this.username = '';
			this.password = '';
			localStorage.clear();
			router.push({ name: 'login' });
		},
		async authForAnonymous() {
			return await callApiAuth({
				username: process.env.VUE_APP_ANONYMOUS_USERNAME,
				password: process.env.VUE_APP_ANONYMOUS_PASSWORD,
			});
		},
	},
});
