export default [
	{
		path: '/home/home-base',
		name: 'base',
		props: true,
		meta: {
			parent: 'home',
			child: 'base',
			auth: true,
			title: 'Home Base',
			taskName: [],
		},
		component: () => import('./home-base/pages/Base.vue'),
	},
];
