export default [
	{
		path: '/master/employee',
		name: 'employee',
		props: true,
		meta: {
			parent: 'master',
			child: 'employee',
			auth: true,
			title: 'Karyawan',
			taskName: ['viewMasterKaryawan', 'editMasterKaryawan'],
		},
		component: () => import('./master-employee/pages/Employee.vue'),
	},
	{
		path: '/master/employee/edit-employee',
		name: 'editEmployee',
		props: true,
		meta: {
			parent: 'master',
			child: 'employee',
			auth: true,
			title: 'Edit Employee',
			taskName: ['editMasterKaryawan'],
		},
		component: () => import('./master-employee/pages/EditMaster.vue'),
	},
];
