export default [
	{
		path: '/change-password',
		name: 'changePassword',
		props: true,
		meta: {
			auth: true,
			title: 'Change Password',
		},
		component: () => import('./change-password/pages/ChangePassword.vue'),
	},
];
