<template>
  <router-view />
</template>

<style>
@import url('@/assets/css/font.css');

* {
  margin: 0;
  font-family: 'CircularStd', -apple-system, BlinkMacSystemFont, 'Segoe UI';
}
</style>
