import CryptoJS from 'crypto-js';
import { GeneralConstants, ErrorConstants } from './constants';

function base64url(input) {
	var base64String = btoa(input);
	return urlConvertBase64(base64String);
}

function urlConvertBase64(input) {
	var output = input.replace(/=+$/, '');
	output = output.replace(/\+/g, '-');
	output = output.replace(/\//g, '_');

	return output;
}

function randomJti() {
	var newJti = '';
	var charset = 'abcdefghijklmnopqrstuvwxyz0123456789';

	for (var i = 0; i < 12; i++) {
		newJti += charset.charAt(Math.floor(Math.random() * charset.length));
	}
	return newJti;
}

function genTokenSign(token, secret) {
	if (token.length != 2) {
		return;
	}
	var hash = CryptoJS.HmacSHA256(token.join('.'), secret);
	var base64Hash = CryptoJS.enc.Base64.stringify(hash);
	return urlConvertBase64(base64Hash);
}

function generateJWT(body, secret) {
	const header = {
		alg: 'HS256',
		typ: 'JWT',
	};
	var token = [];
	token[0] = base64url(JSON.stringify(header));
	token[1] = base64url(JSON.stringify(body));
	token[2] = genTokenSign(token, secret);

	return token.join('.');
}
export async function callApiAuth(payload) {
	var inputPayload = {
		iss: process.env.VUE_APP_CLIENT_ID,
		aud: process.env.VUE_APP_ENDPOINT_AUTH,
		sub: 'api.taskhub.id',
		jti: randomJti(),
		iat: Math.floor(new Date().getTime() / 1000),
		exp: Math.floor(new Date().getTime() / 1000) + 86400,
	};
	const startTime = Date.now();
	const requestOptions = {
		method: 'POST',
		headers: {
			Authorization:
				'Bearer ' + generateJWT(inputPayload, process.env.VUE_APP_SECRET_KEY),
			'Content-Type': 'application/json',
			'X-Tenant': process.env.VUE_APP_X_TENANT,
		},
		body: JSON.stringify({
			payload: payload,
		}),
	};

	try {
		const response = await fetch(
			process.env.VUE_APP_ENDPOINT_AUTH,
			requestOptions,
		);
		const data = await response.json();
		const endTime = Date.now();
		let result = {};
		if (response.status == GeneralConstants.SUCCESS) {
			if (data.result.status == GeneralConstants.OK) {
				result.isOk = true;
				localStorage.setItem('jwtTask', response.headers.get('Th-JwtTask'));
				result.errorKey = '';
				result.errorArgs = [];
				result.errorMessage = '';
			} else if (data.result.status == GeneralConstants.FAIL) {
				result.isOk = false;
				result.errorKey = data.result.errorKey;
				result.errorArgs = data.result.args;
				result.errorMessage =
					'error.' + data.result.errorKey.replace(/[.]/g, '_');
			}
		} else {
			result.isOk = false;
			result.errorKey = data.error;
			result.errorArgs = [];
			result.errorMessage = 'error.' + data.error.replace(/[.]/g, '_');
		}
		result.httpCode = response.status;
		result.body = data.result;
		const timeRunning = endTime - startTime;
		result.latency = timeRunning;
		return result;
	} catch (e) {
		let result = {
			isOk: false,
			errorKey: e,
			errorArgs: [],
			errorMessage: 'error.' + e,
		};
		return result;
	}
	return result;
}

export async function callApiTask(serviceName, payload) {
	if (!localStorage.jwtTask) {
		let result = {
			isOk: false,
			errorArgs: [],
			errorKey: ErrorConstants.SESSION_NOTFOUND,
			errorMessage:
				'error.' + ErrorConstants.SESSION_NOTFOUND.replace(/[.]/g, '_'),
		};
		return result;
	}

	const requestOptions = {
		method: 'POST',
		headers: {
			Authorization: 'Bearer ' + localStorage.jwtTask,
			'Content-Type': 'application/json',
			'X-Tenant': process.env.VUE_APP_X_TENANT,
			'Th-ServiceName': serviceName,
		},
		body: JSON.stringify({
			payload: payload,
		}),
	};

	try {
		const startTime = Date.now();
		const response = await fetch(
			process.env.VUE_APP_ENDPOINT_TASK,
			requestOptions,
		);
		const data = await response.json();
		const endTime = Date.now();
		const timeRunning = endTime - startTime;
		let result = {};
		result.body = data.result;
		result.latency = timeRunning;
		result.httpCode = response.status;

		if (response.status == GeneralConstants.SUCCESS) {
			if (data.result.status == GeneralConstants.OK) {
				result.isOk = true;
				result.errorKey = '';
				result.errorArgs = [];
				result.errorMessage = '';
			} else if (data.result.status == GeneralConstants.FAIL) {
				result.isOk = false;
				result.errorKey = data.result.errorKey;
				result.errorArgs = data.result.args;
				result.errorMessage =
					'error.' + data.result.errorKey.replace(/[.]/g, '_');
			}
		} else {
			result.isOk = false;
			result.errorKey = data.error;
			result.errorArgs = [];
			result.errorMessage = 'error.' + data.error.replace(/[.]/g, '_');
		}
		return result;
	} catch (e) {
		let result = {
			isOk: false,
			errorKey: e,
			errorArgs: [],
			errorMessage: 'error.' + e,
		};
		return result;
	}
	return result;
}

export default { callApiAuth, callApiTask };
