export const GeneralConstants = {
	YES: 'Y',
	NO: 'N',
	EMPTY_STRING: '',
	REF_NULL: -99,
	NEGATIVE_LIMIT: -999,
	POSITIVE_LIMIT: 999,
	NULL: null,
	APPROVED_DOCUMENT: 'R',
	REJECTED_DOCUMENT: 'C',
	OK: 'OK',
	FAIL: 'FAIL',
	SUCCESS: 200,
	PRESENT_EMPLOYEE: 'M',
	OVERTIME_EMPLOYEE: 'L',
	ABSENCE_EMPLOYEE: 'K',
};

export const ErrorConstants = {
	SESSION_NOTFOUND: 'session.not.found.login.to.continue',
};
