export default [
	{
		path: '/report/absence',
		name: 'absence',
		props: true,
		meta: {
			parent: 'report',
			child: 'absence',
			auth: true,
			title: 'Absen',
			taskName: ['viewLaporanAbsen'],
		},
		component: () => import('./report-absence/pages/Absence.vue'),
	},
	{
		path: '/report/absence-history',
		name: 'absenceHistory',
		props: true,
		meta: {
			parent: 'report',
			child: 'absenceHistory',
			auth: true,
			title: 'Riwayat Absen',
			taskName: ['viewHistorySelfAbsen'],
		},
		component: () =>
			import('./report-absence-history/pages/AbsenceHistory.vue'),
	},
	,
	{
		path: '/report/leave-info',
		name: 'leaveInfo',
		props: true,
		meta: {
			parent: 'report',
			child: 'leaveInfo',
			auth: true,
			title: 'Leave Info',
			taskName: ['viewLeaveFormForAdmin'],
		},
		component: () => import('./report-leave-info/pages/LeaveInfo.vue'),
	},
];
