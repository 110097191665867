import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/modules/auth/store/auth.store';

function loadRoutes() {
	const context = require.context('@/modules', true, /.router.js$/i);
	return context
		.keys()
		.map(context)
		.flatMap((m) => m.default);
}

const routes = [
	{
		path: '/',
		name: 'appLayout',
		component: () => import('@/layout/AppLayout.vue'),
		props: true,
		meta: {
			auth: true,
		},
		children: loadRoutes(),
	},
	{
		path: '/login',
		name: 'login',
		props: true,
		meta: {
			auth: false,
			title: 'Login',
		},
		component: () => import('@/modules/auth/pages/Login.vue'),
	},
	{
		path: '/not-found',
		name: 'notfound',
		props: true,
		meta: {
			title: '404 Not Found',
		},
		component: () => import('@/layout/NotFound.vue'),
	},
	{
		path: '/unauthorized',
		name: 'unauthorized',
		props: true,
		meta: {
			auth: true,
			title: '401 Unauthorized',
		},
		component: () => import('@/layout/Unauthorized.vue'),
	},
	{
		path: '/forgot-password',
		name: 'forgot-password',
		props: true,
		meta: {
			auth: false,
			title: 'Forgot Password',
		},
		component: () => import('@/modules/auth/pages/ForgotPassword.vue'),
	},
	{
		path: '/request-reset-password',
		name: 'requestResetPassword',
		props: true,
		meta: {
			auth: false,
			title: 'Request Reset Password',
		},
		component: () => import('@/modules/auth/pages/RequestResetPassword.vue'),
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	const context = useAuthStore();
	let documentTittle = `${process.env.VUE_APP_TITTLE} - ${to.meta.title}`;

	if (to.params.title) {
		documentTittle += ` - `;
	}

	document.title = documentTittle;

	if (!localStorage.jwtTask) {
		localStorage.setItem('jwtTask', '');
	} else {
		context.token = localStorage.jwtTask;
	}

	if ('auth' in to.meta && to.meta.auth && '' == context.token) {
		next({ name: 'login' });
	} else {
		if (to.fullPath == '/') {
			next(process.env.VUE_APP_HOME_BASE_URL);
		}
		next();
	}

	const taskName = JSON.parse(localStorage.userData).tasks.map((v) => v.name);
	if (
		!to.meta.taskName.some((v) => taskName.indexOf(v) >= 0) &&
		to.meta.taskName.length > 0
	) {
		router.push({ path: '/unauthorized' });
	}
});

export default router;
